import { Zakat } from "@ifgengineering/client-invest-sdk";
import { DEFAULT_NISAB_PRICE } from "@Zakat/helpers/zakat/consts";

export interface ZakatSummary {
  zakatableAssets: number;
  whatIOwe: number;
  whatIOwn: number;
  nisab: number;
  zakat: number;
}

const defaultZakatSummary: ZakatSummary = {
  whatIOwe: 0,
  whatIOwn: 0,
  zakatableAssets: 0,
  nisab: DEFAULT_NISAB_PRICE,
  zakat: 0,
};
export const getZakatSummary = (
  zakat?: Zakat,
  nisab?: number
): ZakatSummary => {
  if (!zakat) return defaultZakatSummary;

  const { assets, liabilities } = zakat;

  const whatIOwe =
    liabilities?.reduce((acc, liability) => {
      return acc + liability.amount;
    }, 0) ?? 0;

  const whatIOwn =
    assets?.reduce((acc, asset) => {
      return acc + asset.amount;
    }, 0) ?? 0;

  return {
    whatIOwe,
    whatIOwn,
    zakat: zakat.amountDue ?? 0,
    zakatableAssets: whatIOwn,
    nisab: nisab ?? DEFAULT_NISAB_PRICE,
  };
};
