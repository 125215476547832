import React, { useContext, useEffect, useState } from "react";
import { navigate } from "gatsby";
import LockIcon from "@assets/icons/lock.svg";
import FormButton from "@legacyComponents/FormButton/FormButton.component";
import {
  BreakdownContainer,
  BreakdownText,
  ButtonContainer,
  Header,
  HeaderTextContainer,
  StyledDown,
  StyledUp,
} from "./styles/summary.style";
import { useDispatch, useSelector } from "react-redux";
import { getPortfolioData } from "@components/PortfolioData/selectors";
import { zakatCalculator } from "@Zakat/helpers/zakat";
import {
  DEFAULT_GOLD_PRICE,
  DEFAULT_NISAB_PRICE,
} from "@Zakat/helpers/zakat/consts";
import Tooltip from "@components/Tooltip";
import { Text } from "@ifgengineering/component-library";
import { CurrencyContext } from "@components/CurrencyContext";
import { updateZakatAPI } from "@Zakat/store/actions/zakat";
import { getUserEmail } from "@components/Login/selectors";
import ZakatBreakdown from "@Zakat/pages/summary/zakatBreakdown";
import { useMediaQuery } from "react-responsive";
import { MEDIA_QUERIES } from "@ifgengineering/component-library";
import ZakatSummary from "@Zakat/components/ZakatSummary";
import useAnalytics from "@helpers/useAnalytics";
import PageWrapper from "@Zakat/components/PageLayout/PageWrapper";

const ZakatSummaryPage: React.FC = () => {
  const isMobile = useMediaQuery({ query: MEDIA_QUERIES.PHONE });
  const [showBreakdown, setShowBreakdown] = useState(isMobile);
  const dispatch = useDispatch();
  const userEmail = useSelector(getUserEmail);
  const portfolio = useSelector(getPortfolioData);

  const { formatCurrency, silverPrice } = useContext(CurrencyContext);
  const firePageViewAnalytics = useAnalytics("pageView");
  const fireZakatComplete = useAnalytics("zakatCalculatorCompleted");

  const calculateZakat = zakatCalculator(
    DEFAULT_GOLD_PRICE,
    silverPrice,
    DEFAULT_NISAB_PRICE
  );

  const summary = calculateZakat(portfolio);

  useEffect(() => {
    const firePageView = async (eventName: string) => {
      (await firePageViewAnalytics)({ eventName });
    };
    firePageView("zakat:calculationSummary_pageViewed");
  }, []);

  const handleConfirm = async () => {
    (await fireZakatComplete)({ email: userEmail });

    await dispatch(
      updateZakatAPI({
        data: {
          zakatableAssets: summary.zakatableAssets,
          nisab: summary.nisab,
          amountDue: summary.zakat,
        },
        email: userEmail,
      })
      // @ts-expect-error unwrap is not properly typed
    ).unwrap();

    navigate("/app/charity-allocation");
  };

  return (
    <PageWrapper>
      <HeaderTextContainer>
        <Header>Zakat Summary</Header>
        <Text type="P16" color="SLATE800">
          Thank you for calculating your zakat! We have emailed you this summary
          along with your unique referral link. Please check your junk folder in
          case it has ended up there.
        </Text>
        <Tooltip
          label="Important Information"
          text={
            <>
              Zakatable assets is the % of your assets that you need to pay
              zakat on e.g. on a defined contribution pension you only pay zakat
              on 25% of the asset value which is a proxy used for amount held in
              liquid assets.
              <br />
              <br />
              If the value of your zakatable assets minus your liabilities is
              greater than the Nisab (the silver standard is 21 ounces of silver
              (612.36 grammes) or its equivalent in cash) then you are liable to
              pay zakat.
              <br />
              <br />
              For more information on how the zakat calculation works read our{" "}
              <Text
                as="a"
                href="https://www.islamicfinanceguru.com/zakatcalculatorguidance"
                target="_blank"
                color="BLUE600"
                type="S14"
              >
                detailed guide
              </Text>
            </>
          }
        />
        <br />
        {!isMobile && (
          <BreakdownContainer onClick={() => setShowBreakdown((prev) => !prev)}>
            <BreakdownText>
              {showBreakdown ? "Hide" : "Show"} asset breakdown
            </BreakdownText>
            {showBreakdown ? <StyledDown /> : <StyledUp />}
          </BreakdownContainer>
        )}
      </HeaderTextContainer>
      {showBreakdown && <ZakatBreakdown />}
      <ZakatSummary formatCurrency={formatCurrency} summary={summary} />
      <ButtonContainer>
        <FormButton
          text="Confirm & Email Summary"
          leftArrowLabel="Back"
          disclaimerIcon={<LockIcon />}
          disclaimerText={
            <div>
              We take your{" "}
              <a
                href="https://www.islamicfinanceguru.com/security-policy"
                target="_blank"
                rel="noreferrer"
              >
                privacy seriously
              </a>
              . Our institutional-grade security ensures your data is
              confidential.
            </div>
          }
          onClick={handleConfirm}
          leftArrowOnClick={() => navigate("/app/calculate")}
        />
      </ButtonContainer>
    </PageWrapper>
  );
};

export default ZakatSummaryPage;

export const mockData: {
  owe: number;
  own: number;
  nisab: number;
  total: number;
} = {
  owe: 20,
  own: 800,
  nisab: 330.08,
  total: 19.5,
};
