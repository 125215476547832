import React from "react";
import SummaryPageV1 from "./summaryPageV1";
import SummaryPageV2 from "./summaryPageV2";
import { isZakat2025Enabled } from "@helpers/useFeatureFlag";

export const ZakatSummaryPage = () => {
  return isZakat2025Enabled ? <SummaryPageV2 /> : <SummaryPageV1 />;
};

export default ZakatSummaryPage;
