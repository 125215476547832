import React, { useContext, useEffect, useState } from "react";
import { navigate } from "gatsby";
import LockIcon from "@assets/icons/lock.svg";
import FormButton from "@legacyComponents/FormButton/FormButton.component";
import {
  BreakdownContainer,
  BreakdownText,
  ButtonContainer,
  Header,
  HeaderTextContainer,
  StyledDown,
  StyledUp,
} from "./styles/summary.style";
import { useDispatch, useSelector } from "react-redux";
import Tooltip from "@components/Tooltip";
import { Text } from "@ifgengineering/component-library";
import { CurrencyContext } from "@components/CurrencyContext";
import ZakatBreakdownV2 from "@Zakat/pages/summary/zakatBreakdownV2";
import { useMediaQuery } from "react-responsive";
import { MEDIA_QUERIES } from "@ifgengineering/component-library";
import ZakatSummary from "@Zakat/components/ZakatSummary";
import useAnalytics from "@helpers/useAnalytics";
import PageWrapper from "@Zakat/components/PageLayout/PageWrapper";
import {
  nisabSelector,
  zakatSelector,
} from "@Zakat/store/zakatV2/zakat.selector";
import { getZakatSummary } from "@Zakat/helpers/zakat/zakatV2Helpers";
import { fetchZakatAsync } from "@Zakat/store/zakatV2/zakat.action";

const ZakatSummaryPage: React.FC = () => {
  const zakat = useSelector(zakatSelector);
  const nisab = useSelector(nisabSelector);

  const dispatch = useDispatch();
  const isMobile = useMediaQuery({ query: MEDIA_QUERIES.PHONE });
  const [showBreakdown, setShowBreakdown] = useState(isMobile);

  const { formatCurrency } = useContext(CurrencyContext);
  const firePageViewAnalytics = useAnalytics("pageView");

  const summary = getZakatSummary(zakat, nisab);

  useEffect(() => {
    const firePageView = async (eventName: string) => {
      (await firePageViewAnalytics)({ eventName });
    };
    firePageView("zakat:calculationSummary_pageViewed");
  }, []);

  useEffect(() => {
    dispatch(fetchZakatAsync());
  }, []);

  const handleConfirm = async () => {
    navigate("/app/charity-allocation");
  };

  return (
    <PageWrapper>
      <HeaderTextContainer>
        <Header>Zakat Summary</Header>
        <Text type="P16" color="SLATE800">
          Thank you for calculating your zakat!
        </Text>
        <Tooltip
          label="Important Information"
          text={
            <>
              Zakatable assets is the % of your assets that you need to pay
              zakat on e.g. on a defined contribution pension you only pay zakat
              on 25% of the asset value which is a proxy used for amount held in
              liquid assets.
              <br />
              <br />
              If the value of your zakatable assets minus your liabilities is
              greater than the Nisab (the silver standard is 21 ounces of silver
              (612.36 grammes) or its equivalent in cash) then you are liable to
              pay zakat.
              <br />
              <br />
              For more information on how the zakat calculation works read our{" "}
              <Text
                as="a"
                href="https://www.islamicfinanceguru.com/zakatcalculatorguidance"
                target="_blank"
                color="BLUE600"
                type="S14"
              >
                detailed guide
              </Text>
            </>
          }
        />
        <br />
        {/* TODO: Add back in when we finalise the breakdown v2 */}
        {/* {!isMobile && (
          <BreakdownContainer onClick={() => setShowBreakdown((prev) => !prev)}>
            <BreakdownText>
              {showBreakdown ? "Hide" : "Show"} asset breakdown
            </BreakdownText>
            {showBreakdown ? <StyledDown /> : <StyledUp />}
          </BreakdownContainer>
        )} */}
      </HeaderTextContainer>
      {/* {showBreakdown && <ZakatBreakdownV2 />} */}
      <ZakatSummary formatCurrency={formatCurrency} summary={summary} />
      <ButtonContainer>
        <FormButton
          text="Continue"
          leftArrowLabel="Back"
          disclaimerIcon={<LockIcon />}
          disclaimerText={
            <div>
              We take your{" "}
              <a
                href="https://www.islamicfinanceguru.com/security-policy"
                target="_blank"
                rel="noreferrer"
              >
                privacy seriously
              </a>
              . Our institutional-grade security ensures your data is
              confidential.
            </div>
          }
          onClick={handleConfirm}
          leftArrowOnClick={() => navigate("/app/calculate")}
        />
      </ButtonContainer>
    </PageWrapper>
  );
};

export default ZakatSummaryPage;
