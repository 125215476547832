import React from "react";
import { useSelector } from "react-redux";
import { getPortfolioData } from "@components/PortfolioData/selectors";
import { Section } from "@Zakat/components/SummarySection";
import { assetSchema } from "../../components/SummarySection/constants/assetSchema";

const ZakatBreakdown: React.FC = () => {
  const portfolio = useSelector(getPortfolioData);

  return (
    <div>
      <Section schema={assetSchema} data={portfolio} />
    </div>
  );
};

export default ZakatBreakdown;
